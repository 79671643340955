import { template as template_8ff8c9a4c3104060869d5d87ea2f4387 } from "@ember/template-compiler";
const WelcomeHeader = template_8ff8c9a4c3104060869d5d87ea2f4387(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
