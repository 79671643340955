import { template as template_6a8d7f2581ef4e8b98cbf261b0486b91 } from "@ember/template-compiler";
const FKLabel = template_6a8d7f2581ef4e8b98cbf261b0486b91(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
