import { template as template_c4fa20750fc942acbd1a7671c4dd3b94 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c4fa20750fc942acbd1a7671c4dd3b94(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
